import React from "react";
import MechaIconSvgVariable from "../../public/assets/svg/MechaIconSvgVariable";
import AuthErrorImage from "../../public/assets/svg/AuthErrorImage";
import ServerErrorImage from "../../public/assets/svg/ServerErrorImage";
import NotFoundErrorImage from "../../public/assets/svg/NotFoundErrorImage";

interface ErrorBlockProps {
  statusCode: number;
  reason: string;
}

function ErrorBlock(props: ErrorBlockProps) {
  const { statusCode, reason } = props;

  const ErrorLogginIn = () => {
    return (
      <>
        {/* content and illustration */}
        <div className="flex xs:mt-[119px] lg:mt-0 flex-col lg:flex-row min h-full w-full">
          {/* content */}
          <div className="w-full lg:w-2/5 flex flex-col justify-center items-center">
            <div className="w-max flex flex-col items-center lg:items-start">
              <div className="flex lg:w-[60px] lg:h-[60px] w-[50px] h-[50px] justify-center lg:justify-start dark:text-white text-black">
                <MechaIconSvgVariable />
              </div>
              <p className=" xs:text-center lg:text-left font-bold dark:text-white text-custom-400 text-3xl lg:text-[40px] mt-10">
                Error logging in
              </p>
              <p className="xs:text-center lg:text-left mt-4 text-custom-410 dark:text-custom-420 text-base font-normal">
                Your login could not be completed.
                <br /> Please check the reason below
              </p>
              <p className="p-4 max-w-[348px] break-words dark:text-white rounded-lg bg-[#FDFDFD] border-2 border-mecha-red-100 dark:bg-custom-910 text-base font-normal mt-8">
                {reason ? (
                  reason
                ) : (
                  <>Credentials unmatched error. Try logging in again</>
                )}
              </p>
              {/* )} */}
              <p className="text-center lg:text-left mt-12 text-custom-410 dark:text-custom-420 text-base font-normal">
                Contact for support for any help needed
              </p>
              <a
                href="/auth/signin"
                className="inline-block border border-custom-410 dark:border-0 dark:bg-[#18212F] py-2 px-3 rounded-lg mt-[56px]"
              >
                Back to login
              </a>
            </div>
          </div>
          {/* illustration */}
          <div className="flex-1 flex xs:mt-[46px] lg:mt-0 xs:mb-10 lg:mb-0  justify-center items-center">
            <div className="lg:w-5/6 lg:h-5/6 dark:text-[#91A3B8] text-[#344557]">
              <AuthErrorImage />
            </div>
          </div>
        </div>
        {/* footer */}
        <div className="w-full lg:w-2/5 px-5 lg:px-0 xs:mt-[46px] lg:mt-0 flex flex-row justify-between lg:justify-center lg:gap-[34.25px] text-sm font-normal text-custom-410 dark:text-custom-420 mb-8">
          <a href="/">Home</a>
          <a href="/discuss">Discuss</a>
          <a href="/docs">Docs</a>
          <a href="/privacy">Privacy</a>
          <a href="/terms">Terms</a>
        </div>
      </>
    );
  };
  const InternalServerError = () => {
    return (
      <>
        <div className="flex xs:mt-[119px] lg:mt-0 flex-col lg:flex-row min h-full w-full">
          {/* content */}
          <div className="w-full lg:w-2/5 flex flex-col justify-center items-center">
            <div className="w-max flex flex-col items-center lg:items-start">
              <div className="flex lg:w-[60px] lg:h-[60px] w-[50px] h-[50px] justify-center lg:justify-start dark:text-white text-black">
                <MechaIconSvgVariable />
              </div>
              <p className=" xs:text-center lg:text-left font-bold dark:text-white text-custom-400 text-3xl lg:text-[40px] mt-10">
                Internal Server Error
              </p>
              <p className="xs:text-center lg:text-left mt-4 text-custom-410 dark:text-custom-420 text-base font-normal">
                Sorry there were some server issues while <br /> processing your
                request.
              </p>

              <a
                href="/"
                className="inline-block border border-custom-410 dark:border-0 dark:bg-[#18212F] py-2 px-3 rounded-lg mt-[56px]"
              >
                Back to Home
              </a>
            </div>
          </div>
          {/* illustration */}
          <div className="flex-1 flex xs:mt-[46px] lg:mt-0 xs:mb-10 lg:mb-0  justify-center items-center">
            <div className="lg:w-5/6 lg:h-5/6 flex justify-center items-center dark:text-[#91A3B8] text-[#344557]">
              <ServerErrorImage />
            </div>
          </div>
        </div>
        {/* footer */}
        <div className="w-full lg:w-2/5 px-5 lg:px-0 xs:mt-[46px] lg:mt-0 flex flex-row justify-between lg:justify-center lg:gap-[34.25px] text-sm font-normal text-custom-410 dark:text-custom-420 mb-8">
          <a href="/">Home</a>
          <a href="/discuss">Discuss</a>
          <a href="/docs">Docs</a>
          <a href="/privacy">Privacy</a>
          <a href="/terms">Terms</a>
        </div>
      </>
    );
  };
  const PageNotFound = () => {
    return (
      <>
        <div className="flex xs:mt-[119px] lg:mt-0 flex-col lg:flex-row min h-full w-full">
          {/* content */}
          <div className="w-full lg:w-2/5 flex flex-col justify-center items-center">
            <div className="w-max flex flex-col items-center lg:items-start">
              <div className="flex lg:w-[60px] lg:h-[60px] w-[50px] h-[50px] justify-center lg:justify-start dark:text-white text-black">
                <MechaIconSvgVariable />
              </div>
              <p className=" xs:text-center lg:text-left font-bold dark:text-white text-custom-400 text-3xl lg:text-[40px] mt-10">
                Error 404
              </p>
              <p className="xs:text-center lg:text-left mt-4 text-custom-410 dark:text-custom-420 text-base font-normal">
                Looks like we have lost this page.
                <br />
                The server could not find what was requested.
              </p>

              <a
                href="/"
                className="inline-block border border-custom-410 dark:border-0 dark:bg-[#18212F] py-2 px-3 rounded-lg mt-[56px]"
              >
                Back to Home
              </a>
            </div>
          </div>
          {/* illustration */}
          <div className="flex-1 flex xs:mt-[46px]  lg:mt-0 xs:mb-10 lg:mb-0  justify-center items-center">
            <div className="lg:w-5/6 lg:h-5/6 flex justify-center items-center dark:text-[#91A3B8] text-[#344557]">
              <NotFoundErrorImage />
            </div>
          </div>
        </div>
        {/* footer */}
        <div className="w-full  lg:w-2/5 px-5 lg:px-0 xs:mt-[46px] lg:mt-0 flex flex-row justify-between lg:justify-center lg:gap-[34.25px] text-sm font-normal text-custom-410 dark:text-custom-420 mb-8">
          <a href="/">Home</a>
          <a href="/discuss">Discuss</a>
          <a href="/docs">Docs</a>
          <a href="/privacy">Privacy</a>
          <a href="/terms">Terms</a>
        </div>
      </>
    );
  };
  return (
    <div className="flex font-space-grotesk flex-col dark:bg-dark bg-stormdust-100 xs:min-h-screen lg:h-screen w-full">
      {/* content and illustration */}
      {statusCode === 404 ? (
        <PageNotFound />
      ) : statusCode === 401 ? (
        <ErrorLogginIn />
      ) : (
        <InternalServerError />
      )}
    </div>
  );
}

// tslint:disable-next-line: no-any
ErrorBlock.getInitialProps = ({ res, err }: any) => {
  const statusCode = res ? res.statusCode : err ? err.statusCode : 404;
  return { statusCode };
};

export default ErrorBlock;
