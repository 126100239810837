import React from "react";

const ServerErrorImage = (props: React.ComponentProps<"svg">) => (
  <svg
    width=""
    height=""
    viewBox="0 0 642 392"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M122.287 79.2742L126.983 389.585L414.929 375.498L413.932 64.7685L122.287 79.2742ZM122.287 79.2742L2.8457 23.4684M348.262 92.8807L343.768 319.964M343.768 319.964L398.479 359.039M343.768 319.964L149.404 329.491M2.8457 23.4684L6.73837 354.03L126.902 389.448M2.8457 23.4684L253.534 15.0062L413.439 63.6945M92.6181 187.452L89.4238 348.865M73.4822 285.048L72.2099 349.336M145.561 98.5866L396.112 87.7184L397.62 359.028L149.419 371.646L145.561 98.5866Z"
      stroke="currentColor"
      strokeWidth="4"
      strokeLinecap="round"
    />
    <path
      d="M242.477 42.7557L241.827 42.5176L241.135 42.739C226.267 47.4998 215.571 48.9171 209.162 47.491C206.03 46.7941 204.107 45.4622 203.088 43.6846C202.054 41.8826 201.762 39.2998 202.612 35.7041C202.612 35.7046 202.612 35.7048 202.612 35.7048C202.612 35.7048 202.637 35.618 202.758 35.4371C202.882 35.251 203.079 35.0091 203.373 34.7171C203.964 34.1296 204.846 33.4371 206.028 32.6878C208.384 31.1931 211.738 29.594 215.825 28.2263C223.997 25.492 234.871 23.7574 246.16 25.482C257.611 27.2313 267.486 30.7934 274.322 34.4618C277.748 36.3004 280.343 38.1298 281.986 39.7192C282.81 40.5166 283.338 41.1991 283.625 41.7367C283.897 42.2452 283.871 42.4648 283.873 42.4649C283.873 42.4649 283.873 42.4624 283.875 42.4575C283.593 43.6488 283.274 44.9256 282.47 46.1109C281.718 47.2186 280.445 48.3772 277.986 49.0957C275.455 49.8355 271.625 50.117 265.84 49.2552C260.07 48.3956 252.475 46.4172 242.477 42.7557Z"
      stroke="currentColor"
      strokeWidth="4"
    />
    <rect
      x="1.51384"
      y="1.48954"
      width="197.103"
      height="48.256"
      transform="matrix(0.999976 -0.00693208 0.00924874 0.999957 195.543 135.612)"
      stroke="#E4002B"
      strokeWidth="3"
    />
    <path
      d="M151.976 168.891L197.603 187.352L197.143 137.621L151.506 118.145L151.976 168.891Z"
      stroke="#E4002B"
      strokeWidth="3"
      strokeLinecap="round"
    />
    <path
      d="M361.537 117.196L394.271 133.722L197.924 137.362L150.205 118.661L361.537 117.196Z"
      stroke="#E4002B"
      strokeWidth="3"
      strokeLinecap="round"
    />
    <line
      y1="-1.5"
      x2="45.6725"
      y2="-1.5"
      transform="matrix(0.00924869 0.999957 -0.999976 0.00693204 314.713 138.835)"
      stroke="currentColor"
      strokeWidth="3"
    />
    <line
      y1="-1.5"
      x2="45.6725"
      y2="-1.5"
      transform="matrix(0.00924869 0.999957 -0.999976 0.00693204 320.982 138.792)"
      stroke="currentColor"
      strokeWidth="3"
    />
    <line
      y1="-1.5"
      x2="45.6725"
      y2="-1.5"
      transform="matrix(0.00924869 0.999957 -0.999976 0.00693204 327.252 138.749)"
      stroke="currentColor"
      strokeWidth="3"
    />
    <path
      d="M372.44 158.989C372.493 164.706 367.753 169.447 361.759 169.489C355.765 169.53 350.938 164.855 350.885 159.138C350.832 153.421 355.572 148.679 361.566 148.638C367.56 148.596 372.387 153.272 372.44 158.989Z"
      stroke="currentColor"
      strokeWidth="3"
    />
    <path
      d="M227.21 161.265C227.244 164.88 224.242 167.905 220.412 167.932C216.583 167.958 213.525 164.975 213.492 161.36C213.458 157.745 216.46 154.719 220.29 154.693C224.119 154.666 227.177 157.65 227.21 161.265Z"
      stroke="currentColor"
      strokeWidth="3"
    />
    <path
      d="M254.378 161.076C254.411 164.691 251.41 167.717 247.58 167.743C243.75 167.77 240.693 164.786 240.659 161.171C240.626 157.556 243.628 154.531 247.457 154.504C251.287 154.478 254.344 157.461 254.378 161.076Z"
      stroke="currentColor"
      strokeWidth="3"
    />
    <path
      d="M281.545 160.888C281.579 164.503 278.577 167.528 274.747 167.555C270.917 167.581 267.86 164.598 267.827 160.983C267.793 157.368 270.795 154.342 274.625 154.316C278.454 154.289 281.512 157.273 281.545 160.888Z"
      stroke="currentColor"
      strokeWidth="3"
    />
    <rect
      x="1.51384"
      y="1.48954"
      width="197.103"
      height="48.256"
      transform="matrix(0.999976 -0.00693208 0.00924874 0.999957 195.159 212.358)"
      stroke="#E4002B"
      strokeWidth="3"
    />
    <path
      d="M151.591 245.637L197.218 264.098L196.758 214.367L151.122 194.891L151.591 245.637Z"
      stroke="#E4002B"
      strokeWidth="3"
      strokeLinecap="round"
    />
    <path
      d="M361.152 193.941L393.887 210.467L197.54 214.106L149.821 195.406L361.152 193.941Z"
      stroke="#E4002B"
      strokeWidth="3"
      strokeLinecap="round"
    />
    <line
      y1="-1.5"
      x2="45.6725"
      y2="-1.5"
      transform="matrix(0.00924869 0.999957 -0.999976 0.00693204 314.328 215.581)"
      stroke="currentColor"
      strokeWidth="3"
    />
    <line
      y1="-1.5"
      x2="45.6725"
      y2="-1.5"
      transform="matrix(0.00924869 0.999957 -0.999976 0.00693204 320.598 215.538)"
      stroke="currentColor"
      strokeWidth="3"
    />
    <line
      y1="-1.5"
      x2="45.6725"
      y2="-1.5"
      transform="matrix(0.00924869 0.999957 -0.999976 0.00693204 326.867 215.495)"
      stroke="currentColor"
      strokeWidth="3"
    />
    <path
      d="M372.055 235.735C372.108 241.452 367.368 246.193 361.374 246.235C355.38 246.276 350.553 241.601 350.5 235.884C350.447 230.167 355.187 225.425 361.181 225.384C367.175 225.342 372.002 230.018 372.055 235.735Z"
      stroke="currentColor"
      strokeWidth="3"
    />
    <path
      d="M226.826 238.01C226.859 241.625 223.858 244.651 220.028 244.677C216.198 244.704 213.141 241.72 213.107 238.105C213.074 234.49 216.076 231.465 219.905 231.438C223.735 231.412 226.792 234.395 226.826 238.01Z"
      stroke="currentColor"
      strokeWidth="3"
    />
    <path
      d="M253.993 237.822C254.027 241.437 251.025 244.463 247.195 244.489C243.366 244.516 240.308 241.532 240.275 237.917C240.241 234.302 243.243 231.277 247.073 231.25C250.903 231.224 253.96 234.207 253.993 237.822Z"
      stroke="currentColor"
      strokeWidth="3"
    />
    <path
      d="M281.161 237.633C281.194 241.248 278.193 244.273 274.363 244.3C270.533 244.326 267.476 241.343 267.442 237.728C267.409 234.113 270.411 231.087 274.24 231.061C278.07 231.034 281.127 234.018 281.161 237.633Z"
      stroke="currentColor"
      strokeWidth="3"
    />
    <rect
      x="1.51384"
      y="1.48954"
      width="197.103"
      height="48.256"
      transform="matrix(0.999976 -0.00693208 0.00924874 0.999957 196.844 288.984)"
      stroke="#E4002B"
      strokeWidth="3"
    />
    <path
      d="M153.277 322.262L198.904 340.724L198.444 290.992L152.808 271.516L153.277 322.262Z"
      stroke="#E4002B"
      strokeWidth="3"
      strokeLinecap="round"
    />
    <path
      d="M362.838 270.569L395.573 287.094L199.225 290.734L151.506 272.034L362.838 270.569Z"
      stroke="#E4002B"
      strokeWidth="3"
      strokeLinecap="round"
    />
    <line
      y1="-1.5"
      x2="45.6725"
      y2="-1.5"
      transform="matrix(0.00924869 0.999957 -0.999976 0.00693204 316.014 292.208)"
      stroke="currentColor"
      strokeWidth="3"
    />
    <line
      y1="-1.5"
      x2="45.6725"
      y2="-1.5"
      transform="matrix(0.00924869 0.999957 -0.999976 0.00693204 322.284 292.164)"
      stroke="currentColor"
      strokeWidth="3"
    />
    <line
      y1="-1.5"
      x2="45.6725"
      y2="-1.5"
      transform="matrix(0.00924869 0.999957 -0.999976 0.00693204 328.553 292.122)"
      stroke="currentColor"
      strokeWidth="3"
    />
    <path
      d="M373.741 312.361C373.794 318.078 369.054 322.82 363.06 322.861C357.066 322.903 352.239 318.228 352.186 312.511C352.133 306.794 356.874 302.052 362.867 302.01C368.861 301.969 373.689 306.644 373.741 312.361Z"
      stroke="currentColor"
      strokeWidth="3"
    />
    <path
      d="M228.511 314.637C228.545 318.252 225.543 321.278 221.713 321.304C217.884 321.331 214.826 318.347 214.793 314.732C214.76 311.117 217.761 308.092 221.591 308.065C225.421 308.039 228.478 311.022 228.511 314.637Z"
      stroke="currentColor"
      strokeWidth="3"
    />
    <path
      d="M255.679 314.449C255.713 318.064 252.711 321.089 248.881 321.116C245.051 321.142 241.994 318.159 241.961 314.544C241.927 310.929 244.929 307.903 248.759 307.877C252.588 307.85 255.646 310.834 255.679 314.449Z"
      stroke="currentColor"
      strokeWidth="3"
    />
    <path
      d="M282.846 314.259C282.88 317.874 279.878 320.9 276.048 320.926C272.219 320.953 269.161 317.97 269.128 314.355C269.094 310.739 272.096 307.714 275.926 307.687C279.756 307.661 282.813 310.644 282.846 314.259Z"
      stroke="currentColor"
      strokeWidth="3"
    />
    <path
      d="M417.044 352.966C417.044 352.966 432.237 358.812 445.539 352.966C463.013 345.287 471.968 332.575 480.75 310.604C493.325 279.146 480.332 254.524 480.064 218.591C479.84 188.618 471.72 124.258 471.72 124.258"
      stroke="#E4002B"
      strokeWidth="6"
      strokeLinecap="round"
    />
    <path
      d="M471.735 123.923C471.735 123.923 460.266 74.6405 471.26 60.2121C483.937 43.5753 496.579 52.4349 496.579 52.4349"
      stroke="#E4002B"
      strokeWidth="6"
      strokeLinecap="round"
    />
    <ellipse
      cx="19.7509"
      cy="7.75062"
      rx="19.7509"
      ry="7.75062"
      transform="matrix(-0.108246 0.994124 -0.996916 -0.0784801 506.5 29.8836)"
      fill="#E4002B"
    />
    <mask id="path-36-inside-1_1_272" fill="white">
      <rect x="497.082" y="34.5153" width="28.6137" height="28.6137" rx="4" />
    </mask>
    <rect
      x="497.082"
      y="34.5153"
      width="28.6137"
      height="28.6137"
      rx="4"
      stroke="#E4002B"
      strokeWidth="12"
      mask="url(#path-36-inside-1_1_272)"
    />
    <rect
      x="523.095"
      y="38.4174"
      width="17.3385"
      height="6.19395"
      fill="#E4002B"
    />
    <rect
      x="524.395"
      y="52.7242"
      width="17.3385"
      height="6.19395"
      fill="#E4002B"
    />
    <path
      d="M625.844 74.1844L639.5 61.8285V9.15336L625.844 1.99994M625.844 74.1844V1.99994M625.844 74.1844L569.917 74.1844V1.99994L625.844 1.99994"
      stroke="currentColor"
      strokeWidth="4"
      strokeLinecap="round"
    />
    <circle
      cx="598.531"
      cy="38.4173"
      r="16.2087"
      stroke="currentColor"
      strokeWidth="4"
    />
    <rect
      x="590.727"
      y="34.5154"
      width="2.60124"
      height="7.80373"
      fill="currentColor"
    />
    <rect
      x="603.733"
      y="34.5154"
      width="2.60124"
      height="7.80373"
      fill="currentColor"
    />
    <rect
      x="597.23"
      y="15.0062"
      width="2.60124"
      height="7.80373"
      fill="currentColor"
    />
    <rect
      x="597.23"
      y="54.0248"
      width="2.60124"
      height="7.80373"
      fill="currentColor"
    />
  </svg>
);

export default ServerErrorImage;
