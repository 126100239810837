import React from "react";

const NotFoundErrorImage = (props: React.ComponentProps<"svg">) => (
  <svg
    width=""
    height=""
    viewBox="0 0 717 398"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M87.9096 311.411L6.01953 278.477V234.84L87.9096 261.187M87.9096 311.411V261.187M87.9096 311.411L424.833 308.941V286.816M87.9096 261.187H424.833V286.816M424.833 286.816H186.456"
      stroke="currentColor"
      strokeWidth="8"
    />
    <path
      d="M87.3266 33.1187L5.74219 74.7192L5.74361 227.856L87.3266 255.049M87.3266 33.1187L87.3266 255.049M87.3266 33.1187L423.401 33.1189L423.401 255.049L87.3266 255.049M115.957 61.8383L396.21 61.8382L396.21 230.282L115.957 230.283L115.957 61.8383Z"
      stroke="#E4002B"
      strokeWidth="8"
    />
    <path
      d="M201.717 177.565V178.065H202.217H214.097H214.597V177.565V165.059H224.864H225.364V164.559V154.117V153.617H224.864H214.597V113.448V112.948H214.097H192.75H192.467L192.321 113.191L169.861 150.746L169.79 150.864V151.002V164.559V165.059H170.29H201.717V177.565ZM238.147 172.989L238.15 172.992C242.902 177.244 249.187 179.348 256.953 179.348C264.779 179.348 271.066 177.245 275.759 172.989L275.759 172.989C280.537 168.651 282.884 162.144 282.884 153.567V137.446C282.884 129.11 280.535 122.7 275.764 118.304C271.073 113.863 264.783 111.666 256.953 111.666C249.182 111.666 242.895 113.864 238.143 118.303C233.371 122.698 231.022 129.109 231.022 137.446V153.567C231.022 162.144 233.369 168.651 238.147 172.989ZM266.789 164.318L266.784 164.323C264.747 166.678 261.509 167.906 256.953 167.906C252.524 167.906 249.273 166.74 247.109 164.492C244.999 162.176 243.902 158.686 243.902 153.933V136.897C243.902 132.465 245.053 129.092 247.291 126.708C249.589 124.325 252.788 123.108 256.953 123.108C260.995 123.108 264.135 124.295 266.435 126.623L266.435 126.623L266.444 126.632C268.79 128.888 270.004 132.279 270.004 136.897V153.933C270.004 158.499 268.908 161.936 266.789 164.318ZM320.906 177.565V178.065H321.406H333.286H333.786V177.565V165.059H344.052H344.552V164.559V154.117V153.617H344.052H333.786V113.448V112.948H333.286H311.939H311.655L311.51 113.191L289.049 150.746L288.978 150.864V151.002V164.559V165.059H289.478H320.906V177.565ZM201.717 121.459V153.617H182.113V152.789L200.83 121.459H201.717ZM320.906 121.459V153.617H301.301V152.789L320.019 121.459H320.906Z"
      fill="#FEDD00"
      stroke="#FEDD00"
    />
    <line
      x1="250.466"
      y1="91.9001"
      x2="368.935"
      y2="91.9001"
      stroke="currentColor"
    />
    <line
      x1="138.672"
      y1="98.4874"
      x2="178.718"
      y2="98.4874"
      stroke="currentColor"
    />
    <line
      x1="305.53"
      y1="197.29"
      x2="368.936"
      y2="197.29"
      stroke="currentColor"
    />
    <line
      x1="325.552"
      y1="205.523"
      x2="375.61"
      y2="205.523"
      stroke="currentColor"
    />
    <line
      x1="300.796"
      y1="83.672"
      x2="350.854"
      y2="83.672"
      stroke="currentColor"
    />
    <line
      x1="145.346"
      y1="197.29"
      x2="210.42"
      y2="197.29"
      stroke="currentColor"
    />
    <line
      x1="158.695"
      y1="205.523"
      x2="188.729"
      y2="205.523"
      stroke="currentColor"
    />
    <path
      d="M517.439 112.682V172.787"
      stroke="currentColor"
      strokeWidth="8"
      strokeLinecap="round"
    />
    <path
      d="M629.234 25.4074C629.234 25.4074 656.493 36.4039 669.28 49.2846C683.966 64.0791 694.309 96.2156 694.309 96.2156"
      stroke="currentColor"
      strokeWidth="8"
      strokeLinecap="round"
    />
    <path
      d="M580.011 101.979C580.011 101.979 610.35 87.0511 617.554 69.0444C623.417 54.3898 617.554 28.7002 617.554 28.7002"
      stroke="currentColor"
      strokeWidth="8"
      strokeLinecap="round"
    />
    <path d="M586 335.198H634.389V353.311H586V335.198Z" fill="currentColor" />
    <path
      d="M712 220.541C712 222.308 710.548 225.058 705.187 228.35C700.053 231.502 692.358 234.485 682.495 237.047C662.829 242.154 635.443 245.359 605.039 245.359C574.636 245.359 547.25 242.154 527.584 237.047C517.721 234.485 510.026 231.502 504.892 228.35C499.531 225.058 498.079 222.308 498.079 220.541C498.079 218.775 499.531 216.025 504.892 212.733C510.026 209.581 517.721 206.598 527.584 204.036C547.25 198.929 574.636 195.724 605.039 195.724C635.443 195.724 662.829 198.929 682.495 204.036C692.358 206.598 700.053 209.581 705.187 212.733C710.548 216.025 712 218.775 712 220.541Z"
      stroke="currentColor"
      strokeWidth="8"
    />
    <path
      d="M710.331 204.074C710.331 205.855 708.882 208.605 703.573 211.889C698.482 215.039 690.85 218.021 681.064 220.581C661.551 225.687 634.376 228.891 604.205 228.891C574.034 228.891 546.859 225.687 527.347 220.581C517.561 218.021 509.928 215.039 504.838 211.889C499.528 208.605 498.079 205.855 498.079 204.074C498.079 202.293 499.528 199.543 504.838 196.259C509.928 193.109 517.561 190.128 527.347 187.567C546.859 182.461 574.034 179.257 604.205 179.257C634.376 179.257 661.551 182.461 681.064 187.567C690.85 190.128 698.482 193.109 703.573 196.259C708.882 199.543 710.331 202.293 710.331 204.074Z"
      stroke="currentColor"
      strokeWidth="8"
    />
    <path
      d="M712.663 221.365V104C712.663 48.7715 667.891 4 612.663 4L597.416 4C542.188 4 497.416 48.7716 497.416 104V221.365"
      stroke="currentColor"
      strokeWidth="8"
    />
    <path
      d="M589.253 74.7368C581.714 83.8693 572.722 90.149 564.452 93.0416C556.104 95.9613 549.184 95.2548 544.763 91.7002C540.342 88.1456 538.243 81.5997 539.453 72.9413C540.652 64.3632 545.067 54.4038 552.607 45.2713C560.146 36.1388 569.137 29.859 577.408 26.9664C585.756 24.0468 592.676 24.7532 597.097 28.3079C601.518 31.8625 603.617 38.4084 602.407 47.0668C601.207 55.6448 596.792 65.6043 589.253 74.7368Z"
      stroke="#3283E8"
      strokeWidth="8"
    />
    <path
      d="M588.62 38.8553C589.495 39.6441 590.181 41.2553 589.731 43.9417C589.288 46.5842 587.779 49.7458 585.12 52.6185C582.461 55.4911 579.407 57.2599 576.78 57.9335C574.11 58.6182 572.422 58.0892 571.547 57.3004C570.672 56.5116 569.986 54.9003 570.436 52.214C570.878 49.5715 572.388 46.4099 575.047 43.5372C577.706 40.6645 580.76 38.8958 583.387 38.2222C586.057 37.5375 587.745 38.0664 588.62 38.8553Z"
      stroke="#FEDD00"
      strokeWidth="8"
    />
    <line
      x1="611.542"
      y1="244.419"
      x2="611.542"
      y2="338.281"
      stroke="currentColor"
      strokeWidth="8"
    />
    <path
      d="M570.833 201.083C570.833 201.083 581.58 257.424 570.833 290.828C556.717 334.707 533.169 357.357 492.41 379.75C434.053 411.812 388.172 379.75 321.381 379.75C265.667 379.75 177.883 364.93 177.883 364.93"
      stroke="#E4002B"
      strokeWidth="8"
      strokeLinecap="round"
    />
    <path
      d="M145.346 364.969C145.346 364.969 53.6422 381.555 26.9225 364.969C-3.88679 345.844 12.6939 327.056 12.6939 327.056"
      stroke="#E4002B"
      strokeWidth="8"
      strokeLinecap="round"
    />
    <ellipse
      cx="15.6023"
      cy="7.08614"
      rx="15.6023"
      ry="7.08614"
      transform="matrix(0.902829 0.43 -0.439304 0.898338 6.22583 310.318)"
      fill="#E4002B"
    />
    <path
      d="M6.85376 317.176L16.4294 303.946C17.3241 302.71 19.0041 302.342 20.3333 303.092L37.8247 312.956C39.5821 313.947 39.8799 316.355 38.4169 317.744L26.8767 328.703"
      stroke="#E4002B"
      strokeWidth="6"
      strokeLinecap="round"
    />
    <path
      d="M28.3429 291.921L32.6797 293.669L25.251 304.491L21.5091 301.752L28.3429 291.921Z"
      fill="#E4002B"
    />
    <path
      d="M41.3291 297.526L46.9472 300.042L38.7286 312.224L33.7559 308.583L41.3291 297.526Z"
      fill="#E4002B"
    />
    <path
      d="M151.374 357.367C150.791 357.34 146.068 355.779 142.09 354.272L155.616 351.017L154.271 349.106L141.025 336.733L159.281 346.272L162.486 326.167L165.351 346.547L166.695 344.018L184.549 342.236L171.509 349.887L184.003 353.042L173.119 358.557"
      stroke="#FEDD00"
      strokeWidth="4"
    />
  </svg>
);

export default NotFoundErrorImage;
